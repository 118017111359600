:root {
  --red : #fe4a49;
  --blue : #2ab7ca;
  --yellow : #fed766;
  --charcoal : #36454f;
  --medium-dark-grey : #333333;
  --dark-grey : #121212;
  --light-grey : #dddddd;
  --grey : #555555;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--medium-dark-grey);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
header {
  text-align : center;
  height : 250px;
}
header .skew {
  overflow: hidden;
  background : var(--blue);
  transform : skewY(-5deg);
  transform-origin: 0;
  height : 100%;
  width : 100%;
}
header div.title {
  font-size : 2rem;
  font-weight: bold;
  transform : translate(0, -550%);
}
div.input {
  display : grid;
  place-items : center;
  width : 100%;
  max-width: 400px;
}
div.input input {
  width : 80%;
  font-size : 1.25rem;
  background-color: var(--charcoal);
  color : var(--light-grey);
}
div.button {
  display : grid;
  place-items: center;
  margin-top : 10px;
  margin-bottom : 10px;
}
div.input {
  margin-top : 10px;
  margin-bottom : 10px;
}
div.button button {
  font-size : 1.25rem;
  padding : 10px;
  border-radius : 5px;
  border : none;
}
div.sign-up-app {
  width : 100%;
  height : calc(100vh - 250px);
  display : grid;
  place-items : center;
  background-color: var(--medium-dark-grey);
}
div.sign-up-app div.stage {
  background-color: var(--dark-grey);
  padding : 25px;
  border-radius: 10px;
  display : grid;
  place-items : center;
}
div.sign-up-app div.stage div.title, 
div.sign-up-app div.stage div.thank-you {
  color : var(--light-grey);
  font-weight: bold;
  font-size : 2rem;
  text-align: center;
}

div.sign-up-app div.stage.error div.input input {
  border-bottom : 2px solid var(--red);

}
div.sign-up-app div.stage.error div.error-message {
  text-align: center;
  color : var(--red);
  
}
div.sign-up-app div.stage div.button.login button {
  background-color : var(--yellow);
}

